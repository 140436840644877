import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITeam } from '../models/ITeam';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { IContact } from '../models/IContact';
import { IFeature } from '../models/IFeature';
import { OpeningTime } from '../models/OpeningTime';
import { FAQ } from '../models/faq';
import { QVC } from '../models/qvc';
import qvc from '../../data/qvc.json';


@Injectable({
  providedIn: 'root'
})


export class DataService {

  GetOpeningTimes() {
    return this.http.get<OpeningTime[]>('data/openingTimes.json');
  }

  GetQvcItem(id: string): QVC {
    return qvc.find(w => w.id === id);
  }

  constructor(private http: HttpClient) { }

  GetTeams(): Observable<ITeam[]> {
    return this.http.get<ITeam[]>('data/team.json');
  }

  GetFAQs(): Observable<FAQ[]> {
    return this.http.get<FAQ[]>('data/faq.json');
  }

  GetFeatures(): Observable<IFeature[]> {
    return this.http.get<IFeature[]>('data/feature.json');
  }

  SendContactForm(contact: IContact) {
    const data = JSON.stringify(contact);
    return this.http.post<string>('https://8j3gl0zm1c.execute-api.eu-west-2.amazonaws.com/default/PhoenixContactForm', data );
  }
}
