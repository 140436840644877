[
  {
    "id": "Vertical",
    "mainImage": "./assets/images/vertical-lounge-green.jpg",
    "title": "Vertical Blinds",
    "subtitle": "Top quality fabrics and components.",
    "tl": {
      "title": "Quality",
      "subtitle": "simply the best on the market",
      "description": "Top quality fabrics and components from the leading UK suppliers all made-to-measure for that perfect fit. UV resistant components and thick aluminium headrails used for long lasting use",
      "icon": "far fa-gem"
    },
    "tr": {
      "title": "Service",
      "subtitle": "designed, measured, made and fitted",
      "description": "We will help you design, we will measure and get your vertical blinds manufactured to the exact size and fit them as well. And don't forget we have a showroom with a landline number should you need to get hold of us.",
      "icon": "fa-solid fa-tools"
    },
    "bl": {
      "title": "Choice",
      "subtitle": "1000's of colours and designs to choose from",
      "description": "5 Different Headrail Colours, \n3.5\" or 5\" Slat Widths \n Cord or Wand Control \n Straight, sloping or curved \n 1000's of fabrics to choose from, plains, pastels, designs, vibrant colours.",
      "icon": "far fa-list-alt"
    },
    "br": {
      "title": "Child Safe",
      "subtitle": "Child safe options on cords and wands",
      "description": "Fitted with Cord tidies as standard to keep cords under tension and tight to the wall or for complete peace of mind opt for our wand control system, twist the wand to rotate the slats and pull the from side to side to slide the slats across.",
      "icon": "fa-solid fa-child"
    }
  },
  {
    "id": "Roller",
    "mainImage": "./assets/images/roller-lounge-beige-2.jpg",
    "title": "Roller Blinds",
    "subtitle": "Top quality fabrics and components.",
    "tl": {
      "title": "Quality",
      "subtitle": "simply the best on the market",
      "description": "We only use the very best fabrics and components from the top UK manufacturers. Our rollers will give you trouble free service for many years",
      "icon": "far fa-gem"
    },
    "tr": {
      "title": "Service",
      "subtitle": "designed, measured, made and fitted",
      "description": "We will help you design, we will measure and get your roller blinds manufactured to the exact size and fit them as well. And don't forget we have a showroom with a landline number should you need to get hold of us.",
      "icon": "fa-solid fa-tools"
    },
    "bl": {
      "title": "Choice",
      "subtitle": "any style, shape and colour",
      "description": "100's of plain and patterned fabrics Several Scalloped bottom styles. Lots of extra finishing touches. \n\r Perfect-Fit frame option. \n \n Because we're independent we can source from many different suppliers, not just one.",
      "icon": "far fa-list-alt"
    },
    "br": {
      "title": "Value",
      "subtitle": "built to last",
      "description": "With all that quality, choice and service you can be sure you're getting the very best value! Blinds that last for many years.",
      "icon": "far fa-money-bill-alt"
    }
  },
  {
    "id": "Venetian",
    "mainImage": "./assets/images/venetian-wood-bedroom-white.jpg",
    "title": "Venetian Blinds",
    "subtitle": "Wood and Aluminium options.",
    "tl": {
      "title": "Quality",
      "subtitle": "simply the best on the market",
      "description": "Wood - made from natural sustainable sources \n Aluminium - Thicker gauge with superior paint.",
      "icon": "far fa-gem"
    },
    "tr": {
      "title": "Service",
      "subtitle": "designed, measured, made and fitted",
      "description": "We will help you design, we will measure and get your venetians manufactured to the exact size and fit them as well. And don't forget we have a showroom with a landline number should you need to get hold of us.",
      "icon": "fa-solid fa-tools"
    },
    "bl": {
      "title": "Choice",
      "subtitle": "any style, shape and colour",
      "description": "100's of Plain and Metallic aluminium finishes \n 4 different slat sizes 16mm, 25mm, 35mm & 50mm \n Perfect-Fit & Intu frame options. \n Natural and painted Wood finishes",
      "icon": "far fa-list-alt"
    },
    "br": {
      "title": "Value",
      "subtitle": "Quality, Choice & Service",
      "description": "With all that quality, choice and service you can be sure you're getting the very best value! Blinds that last for many years.",
      "icon": "far fa-money-bill-alt"
    }
  },
  {
    "id": "Pleated",
    "mainImage": "./assets/images/pleated-dining-grey-lg.jpg",
    "title": "Pleated Blinds",
    "subtitle": "Perfect for conservatories.",
    "tl": {
      "title": "Quality",
      "subtitle": "Sourced from the best UK manufacturers",
      "description": "Heat reflective and Dual Cell fabrics are industry leading and help the conservatory be usable all year round. \n All PVCu components made to last and not discolour in the sun.",
      "icon": "far fa-gem"
    },
    "tr": {
      "title": "Service",
      "subtitle": "designed, measured, made and fitted",
      "description": "We will help you design, we will measure and get your pleated blinds manufactured to the exact size and fit them as well. And don't forget we have a showroom with a landline number should you need to get hold of us.",
      "icon": "fa-solid fa-tools"
    },
    "bl": {
      "title": "Perfect Fit",
      "subtitle": "any style, shape and colour",
      "description": "Perfect-Fit by Louvolite is the ideal choice for the perfect look for your conservatory \n \n Separate frame and blind for each glass pane makes your blinds look part of the conservatory.",
      "icon": "far fa-clone"
    },
    "br": {
      "title": "Value",
      "subtitle": "Quality, Choice & Service",
      "description": "Perfect-Fit by Louvolite is the ideal choice for the perfect look for your consevatory \n \n Separate frame and blind for each glass pane makes your blinds look part of the conservatory.",
      "icon": "far fa-money-bill-alt"
    }
  },
  {
    "id": "Conservatory",
    "mainImage": "./assets/images/conservatory.jpg",
    "title": "Conservatory Blinds",
    "subtitle": "Top quality fabrics and components.",
    "tl": {
      "title": "Quality",
      "subtitle": "simply the best on the market",
      "description": "Beautiful fabrics from the UK's leading suppliers \n Quality components made to last \n Manufactured to the highest standards.",
      "icon": "far fa-gem"
    },
    "tr": {
      "title": "Service",
      "subtitle": "designed, measured, made and fitted",
      "description": "We will help you design, we will measure and get your conservatory blinds manufactured to the exact size and fit them as well. And don't forget we have a showroom with a landline number should you need to get hold of us.",
      "icon": "fa-solid fa-tools"
    },
    "bl": {
      "title": "Choice",
      "subtitle": "any type, style, shape and colour",
      "description": "Vertical, Pleated, Venetians & Rollers \n Perfect-Fit and Intu options \n Any shape any size \n 100's of plain and patterned designs",
      "icon": "far fa-list-alt"
    },
    "br": {
      "title": "Value",
      "subtitle": "Quality, Choice & Service",
      "description": "Quality, choice, great service and great prices = the best value for money. \n Our heat reflective blinds also help to keep your conservatory warmer in the winter and cooler in the summer making it more useable all day long and all year long.",
      "icon": "far fa-money-bill-alt"
    }
  },
  {
    "id": "Perfect",
    "mainImage": "./assets/images/perfect-fit.jpg",
    "title": "Perfect-Fit Blinds",
    "subtitle": "No Screw Fit System.",
    "tl": {
      "title": "Perfect Fit",
      "subtitle": "simply the best on the market",
      "description": "Industry leading blind frame system. \n  Fits most PVCu window systems \n Quality components made to last and not discolour \n Each glass pane gets a blind with a frame",
      "icon": "fa-solid fa-clone"
    },
    "tr": {
      "title": "Service",
      "subtitle": "designed, measured, made and fitted",
      "description": "We will help you design, we will measure and get your perfect fit blinds manufactured to the exact size and fit them as well. And don't forget we have a showroom with a landline number should you need to get hold of us.",
      "icon": "fa-solid fa-tools"
    },
    "bl": {
      "title": "Choice",
      "subtitle": "any style, shape and colour",
      "description": "Perfect-Fit blinds are very versatile with roller, pleated and venetian options. Because they are anchored to the glass pane they are suitable for windows and doors.",
      "icon": "far fa-list-alt"
    },
    "br": {
      "title": "Simple Fit",
      "subtitle": "No tools needed - just clips into place",
      "description": "Very thin brackets are slotted between the rubber seals and the glass. \n  The blind is surrounded by the Perfect-Fit frame and becomes a complete cassette. \n The cassette is then clipped into the protruding brackets. \n Just unclip again for ease of cleaning. \n No tools, no drilling and no holes needed, simply click into place.",
      "icon": "fa-solid fa-wrench"
    }
  },
  {
    "id": "Intu",
    "mainImage": "./assets/images/intu-venetian-close.jpg",
    "title": "Intu Blinds",
    "subtitle": "Superb slim fit frame system",
    "tl": {
      "title": "Quality",
      "subtitle": "High quality components designed to last",
      "description": "Supplied by Eclipse, a world renowned manufacturer of blinds and a leading supplier of quality components to the UK ",
      "icon": "far fa-gem"
    },
    "tr": {
      "title": "Service",
      "subtitle": "designed, measured, made and fitted",
      "description": "We will help you design, we will measure and get your Intu manufactured to the exact size and fit them as well. And don't forget we have a showroom with a landline number should you need to get hold of us.",
      "icon": "fa-solid fa-tools"
    },
    "bl": {
      "title": "Choice",
      "subtitle": "Roller, Pleated and Venetian options",
      "description": "Intu blinds come in three different types of blind, Roller, Pleated and Venetian blinds. Each fitted discretely into each glass pane",
      "icon": "far fa-list-alt"
    },
    "br": {
      "title": "Value",
      "subtitle": "Quality, Choice & Service",
      "description": "With all that quality, choice and service you can be sure you're getting the very best value! Blinds that last for many years.",
      "icon": "far fa-money-bill-alt"
    }
  },
  {
    "id": "Shutters",
    "mainImage": "./assets/images/shutters.jpg",
    "title": "Wooden Plantation Shutters",
    "subtitle": "MDF and Hardwood Options",
    "tl": {
      "title": "Quality",
      "subtitle": "simply the best on the market",
      "description": "Made from natural sustainable Basswood. \n Kiln dried to suit UK conditions. \n Proper mortice and tenon joints.",
      "icon": "far fa-gem"
    },
    "tr": {
      "title": "Service",
      "subtitle": "designed, measured, made and fitted",
      "description": "We will help you design, we will measure and get your shutters manufactured to the exact size and fit them as well. And don't forget we have a showroom with a landline number should you need to get hold of us.",
      "icon": "fa-solid fa-tools"
    },
    "bl": {
      "title": "Choice",
      "subtitle": "any style, shape and colour",
      "description": "Natural wood finishes \n White and coloured paint finishes. \n 5 different slat sizes. \n Full Height, Cafe Style, Bi-Fold or Tracked \n Natural Wood, MDF and PVC options",
      "icon": "far fa-list-alt"
    },
    "br": {
      "title": "Value",
      "subtitle": "built to last over 30 years",
      "description": "Not only are these shutters built to last over 30 years but will also add value to your property and could make it easier to sell when your ready to move.",
      "icon": "far fa-money-bill-alt"
    }
  },
  {
    "id": "Curved",
    "mainImage": "./assets/images/curved.jpg",
    "title": "Curved Vertical Blinds",
    "subtitle": "The best curved headrails available in the UK",
    "tl": {
      "title": "Quality",
      "subtitle": "simply the best on the market",
      "description": "Our Benthin curved headrail system is designed to be curved, using the latest equipment - making sure you have the smoothest curve and trouble free operation",
      "icon": "far fa-gem"
    },
    "tr": {
      "title": "Service",
      "subtitle": "designed, measured, made and fitted",
      "description": "We will help you design, we will measure and get your curved vertical blinds manufactured to the exact size and fit them as well. And don't forget we have a showroom with a landline number should you need to get hold of us.",
      "icon": "fa-solid fa-tools"
    },
    "bl": {
      "title": "Choice",
      "subtitle": "any style, shape and colour",
      "description": "We'll shape the vertical blind headrail to almost any natural curve as well as hockey-stick bends to suit most bay situations",
      "icon": "far fa-list-alt"
    },
    "br": {
      "title": "Value",
      "subtitle": "Quality, Choice and Service",
      "description": "With all that quality, choice and service you can be sure you're getting the very best value! Blinds that last for many years.",
      "icon": "far fa-money-bill-alt"
    }
  },
  {
    "id": "Motorised",
    "mainImage": "./assets/images/roller-lounge-beige-2.jpg",
    "title": "Roller Blinds",
    "subtitle": "Top quality fabrics and components.",
    "tl": {
      "title": "Quality",
      "subtitle": "simply the best on the market",
      "description": "",
      "icon": "far fa-gem"
    },
    "tr": {
      "title": "Service",
      "subtitle": "designed, measured, made and fitted",
      "description": "We will help you design, we will measure and get your shutters manufactured to the exact size and fit them as well. And don't forget we have a showroom with a landline number should you need to get hold of us.",
      "icon": "fa-solid fa-tools"
    },
    "bl": {
      "title": "Choice",
      "subtitle": "any style, shape and colour",
      "description": "",
      "icon": "far fa-list-alt"
    },
    "br": {
      "title": "Value",
      "subtitle": "Quality, Choice and Service",
      "description": "With all that quality, choice and service you can be sure you're getting the very best value! Blinds that last for many years.",
      "icon": "far fa-money-bill-alt"
    }
  },
  {
    "id": "dijon",
    "mainImage": "./assets/images/dijon-light-grey.jpg",
    "title": "Dijon Blinds",
    "subtitle": "Huge range of patterns and styles to choose from",
    "tl": {
      "title": "Quality",
      "subtitle": "Luxury and lavish fabrics",
      "description": "Dijon blinds provide fantastic quality fabrics guaranteed to bring a modern and classy look to fit a wide range of interior styles.",
      "icon": "far fa-gem"
    },
    "tr": {
      "title": "Service",
      "subtitle": "designed, measured, made and fitted",
      "description": "We will help you design, we will measure and get your shutters manufactured to the exact size and fit them as well. And don't forget we have a showroom with a landline number should you need to get hold of us.",
      "icon": "fa-solid fa-tools"
    },
    "bl": {
      "title": "Choice",
      "subtitle": "any style, shape and colour",
      "description": "Choose from 20 stripe, 5 oval and 5 classic fabric options as well as 6 headrail colours to find the perfect blinds to fit in with your home and style.",
      "icon": "far fa-list-alt"
    },
    "br": {
      "title": "Value",
      "subtitle": "Quality, Choice and Service",
      "description": "With all that quality, choice and service you can be sure you're getting the very best value! Blinds that last for many years.",
      "icon": "far fa-money-bill-alt"
    }
  }
]
